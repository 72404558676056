<template>
  <b-card
    class="stockstatuschange-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stockstatuschangeForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="编号 ZTZH+22 01 01 12 12 12 1234 年+月+日+小时+分+秒+4位随机数"
            label-for="change_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="change_no"
              size="sm"
              v-model="stockstatuschange.change_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="stock_id"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="stockstatuschange.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="转换后良品库存数量"
            label-for="goodstock"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="goodstock"
              size="sm"
              v-model="stockstatuschange.goodstock"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="转换后良品库位ID"
            label-for="goodlocation"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="goodlocation"
              size="sm"
              v-model="stockstatuschange.goodlocation"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="转换后不良品库存数量"
            label-for="badstock"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="badstock"
              size="sm"
              v-model="stockstatuschange.badstock"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="转换后不良品库位ID"
            label-for="badlocation"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="badlocation"
              size="sm"
              v-model="stockstatuschange.badlocation"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="申请原因"
            label-for="reason"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="reason"
              size="sm"
              v-model="stockstatuschange.reason"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态转换单状态   stock_status_change"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="stockstatuschange.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="stockstatuschange.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="stockstatuschange.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="stockstatuschange.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="stockstatuschange.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="stockstatuschange.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stockstatuschangeStore from './stockstatuschangeStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stockstatuschange: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stockstatuschange')) store.registerModule('stockstatuschange', stockstatuschangeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockstatuschange')) store.unregisterModule('stockstatuschange')
    })

    const edit = function() {
      store.dispatch('stockstatuschange/edit', {id: this.id}).then(res => {
        this.stockstatuschange = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stockstatuschange/view', {id: this.id}).then(res => {
        this.stockstatuschange = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stockstatuschange/save', this.stockstatuschange).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
